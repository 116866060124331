<template>
    <div class="content">
        <van-tabbar style="background: rgb(247,247,247)" v-model="active">
            <van-tabbar-item style="background: rgb(247,247,247)" icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item style="background: rgb(247,247,247)" icon="shop-o">商城</van-tabbar-item>
            <van-tabbar-item style="background: rgb(247,247,247)" icon="user-o">我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "Tabbar",
        data(){
            return{
                active:0,

                d:{
                    0:'/article',
                    1:'/shop',
                    2:'/profile'
                }
            }
        },
        watch:{
            active:function (a,b){
                // console.log(this)
                this.$router.push(this.d[this.active]);
                this.$store.commit('setPage',this.active)
                // localStorage.setItem('page',a)
            }
        },
        created(){
            this.active=this.$store.state.page
        },
        methods:{
        }
    }
</script>

<style scoped>
    .content{
        position: fixed;
        bottom: 0;
    }
</style>
