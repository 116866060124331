<template>
    <div id="app">
        <transition name="slide-fade">
            <keep-alive include="Article,shop,profile">
                <router-view></router-view>
            </keep-alive>
        </transition>

        <Tabbar v-if="$route.meta.type"></Tabbar>

    </div>
</template>

<script>
    import Tabbar from "./component/Tabbar";

    export default {
        name: 'App',
        components: {
            Tabbar
        },
    }
</script>

<style>
    #app {
        margin: unset;
        padding: unset;
        height: calc(100vh - 50px);
        height: calc(100vh - 50px - constant(safe-area-inset-bottom));
        height: calc(100vh - 50px - env(safe-area-inset-bottom));

    }

    .slide-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-fade-leave-active {
        /*transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(-10px);
        opacity: 0;
    }
</style>
