"use strict";

import Vue from 'vue';
import axios from "axios";
import Toast from "vant/lib/toast";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = "https://app.modijiaoyu.com/api/";//这是es测试接口
// axios.defaults.baseURL = "http://192.168.2.24:7501/api/";//这是es测试接口
axios.defaults.baseURL = "https://serverv2.modijiaoyu.com/api/";
// axios.defaults.baseURL = "https://api.yuechengapp.com/api/";
axios.defaults.headers.common['Authorization'] = localStorage.token;
axios.defaults.timeout = 80000;

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        if(!Object.prototype.hasOwnProperty.call(config.headers,'token'))
        {
            // axios.defaults.headers.common['Authorization']=localStorage.getItem('token')
            config.headers.Authorization=localStorage.getItem('token')
        }
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
//         console.log(response)
//         console.log('response')
        if (response.data.status === 'error') {
            // Message.destroy();
            // Toast.failed(response.data.message);
        }
        // Do something with response data
        return response;
    },
    function (error) {
//         console.log('error')
//         console.log(error.response)
        if (error && error.response) {

            // Message.destroy();
            let mmsg = "";
            let _code=JSON.parse(error.response.data.code);
            switch (_code) {
                case 1001:
                    mmsg = '校验异常';
                    if (error.response.data.content === 'token令牌不能为空') {
                        window.location.href = "#/article";
                    }
                    break;
                case 1002:
                    mmsg = '请兑换套餐';
                    // window.location.href = "#/login";
                    break;
                case 1003:
                    mmsg = '请求过于频繁，请稍后再试';
                    break;
                case 1004:
                    mmsg = '验证码错误';
                    break;
                case 1005:
                    mmsg = '操作异常';
                    break;
                case 1006:
                    mmsg = '您无权限操作';
                    break;
                case 1007:
                    mmsg = 'token过期';
                    // window.location.href = "";
                    localStorage.removeItem('token');
                    localStorage.setItem('loginStatus','0')
                    location.reload();
                    break;
                case 1008:
                    mmsg = '刷新token失败，请重新登陆';
                    window.location.href = "#/article";
                    break;
                case 1009:
                    mmsg = '网络超时';
                    break;
                case 1010:
                    mmsg = '格式异常';
                    break;
                case 1011:
                    mmsg = '用户中心报错';
                    break;
                case 1041:
                    mmsg = '登陆已过期，请重新登陆';
                    window.location.href = "#/article";
                    break;
                case 1500:
                    mmsg='服务器出现问题，暂无法连接';
                    break;
                default:
                    mmsg = '';
                    break;
            }
//             console.log(mmsg)
            Toast(error.response.data.content != '' ? error.response.data.content : mmsg);
        } else {
            Toast('连接服务器失败,请检查网络设置');
        }
        // if (error.response.data.content.hasOwnProperty('msg')){
        //   Message.error(error.response.data.content);
        // }else{
        //   Message.error(error.response.data.content);
        //
        // }
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue, options) {
    console.log(options)
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
