import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import 'vant/lib/index.css';
import store from './store'
import './plugins/axios'
Vue.config.productionTip = false;
Vue.use(vant);
import wx from 'weixin-js-sdk';
Vue.use(wx);

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
require('froala-editor/js/languages/zh_cn')
import "froala-editor/js/plugins.pkgd.min.js";
// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
import 'font-awesome/css/font-awesome.css'
Vue.use(VueFroala)


// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// Vue.use(vConsole)
//如要正式服与测试服切换，要改三处。1.router下面的we_chat_id;2.axios下面的路由;3.main里的appid;
// 其中测试服的运行后的端口号需要是8080；否则，会报redirect_url错误
import wechatAuth from './plugins/wechatAuth'
Vue.use(wechatAuth, {appid: 'wx5f9d0f9dcc6dcb0b'});
// Vue.use(wechatAuth, {appid: 'wx8f57139ab130ffad'});//测试用appid
Vue.config.ignoredElements=[
  "wx-open-launch-app"
]
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
