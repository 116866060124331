const SCOPES = ['snsapi_base', 'snsapi_userinfo']


class VueWechatAuthPlugin {

    install(Vue, options) {
        let wechatAuth = this;
        this.setAppId(options.appid);
        Vue.mixin({
            created: function () {
                this.$wechatAuth = wechatAuth
            }
        })

    }

    constructor() {
        this.appid = null
        this.redirect_uri = null
        this.scope = SCOPES[0]
        this._code = null
        this._redirect_uri = null
    }

    static makeState() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    }

    setAppId(appid) {
        this.appid = appid
    }

    set redirect_uri(redirect_uri) {
        this._redirect_uri = encodeURIComponent(redirect_uri)
    }

    get redirect_uri() {
        return this._redirect_uri
    }

    get state() {
        return localStorage.getItem("wechat_auth:state")
    }

    set state(state) {
        localStorage.setItem("wechat_auth:state", state)
    }

    get authUrl() {
        if (this.appid === null) {
            throw "appid must not be null"
        }
        if (this.redirect_uri === null) {
            throw "redirect uri must not be null"
        }
        this.state = VueWechatAuthPlugin.makeState();
        return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirect_uri}&response_type=code&scope=${this.scope}&state=${this.state}&connect_redirect=1#wechat_redirect`
    }

    returnFromWechat() {
        // 截取url中的code方法
        let url = location.search;
        this.winUrl = url
        let theRequest = new Object()
        if (url.indexOf("?") != -1) {
            let str = url.substr(1)
            let strs = str.split("&")
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
            }
        }
        this._code=theRequest
    }

    get code() {
        if (this._code === null) {
            throw "Not get the code from wechat server!"
        }
        // console.log(this)
        // console.log('this._code: ' + this._code)
        let code = this._code
        this._code = null
        // console.log('code: ' + code)
        return code
    }
}

const vueWechatAuthPlugin = new VueWechatAuthPlugin();

export default vueWechatAuthPlugin
