import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import wechatAuth from '../plugins/wechatAuth'

const qs = require('query-string')
import store from '../store'
// import wx from 'weixin-js-sdk'
import axios from "axios";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject){
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        alias: '/article',
        meta: {
            title: '文章列表',
            type:'tabbar'
        },
        component: () => import('../components/Article.vue'),
    },
    {
        path: '/search',
        meta: {
            title: '搜索'
        },
        component: () => import('../components/ArticleSearch.vue'),
    },
    {
        path: '/detail',
        meta: {
            title: '文章'
        },
        component: () => import('../components/Detail.vue'),
    },
    {
        path: '/evaluate',
        meta: {
            title: '评价'
        },
        component: () => import('../components/evaluate/index.vue'),
    },
    {
        path: '/profile',
        meta: {
            title: '我的',
            type:'tabbar'
        },
        component: () => import('../components/profile.vue'),
    },
    {
        path: '/shop',
        meta: {
            title: '商城',
            type:'tabbar'
        },
        component: () => import('../components/shop.vue'),
    },
    {
        path: '/scan',
        meta: {
            title: '扫码兑换',
        },
        component: () => import('../components/scan.vue'),
    },
    {
        path: '/gift',
        meta: {
            title: '详情'
        },
        component: () => import('../components/gift.vue'),
    },
    {
        path: '/point',
        meta: {
            title: '墨豆明细',

        },
        component: () => import('../components/point.vue'),
    },
    {
        path: '/record',
        meta: {
            title: '兑换记录'
        },
        component: () => import('../components/ExchangeRecord.vue'),
    },
    {
        path: '/qrcode',
        meta: {
            title: '二维码'
        },
        component: () => import('../components/QRcode.vue'),
    },
    {
        path: '/ec',
        meta: {
            title: '课程入场券'
        },
        component: () => import('../components/EnterClassCoupon/enterClass.vue'),
    },
    {
        path: '/login',
        meta: {
            title: '绑定'
        },
        component: () => import('../components/login/login.vue'),
    },
    {
        path: '/activity',
        meta: {
            title: '活动详情',
            check_token:false
        },
        component: () => import('../components/yuecheng/activityDetail.vue'),
    },
]

const router = new VueRouter({
    // mode:'history',
    routes,
});


router.beforeEach((to, from, next) => {
    document.title = to.meta.title;

    let loginStatus = Number(store.state.loginStatus);
    if (localStorage.getItem('loginStatus')) {
        loginStatus = Number(localStorage.getItem('loginStatus'))
    }
    console.log(window.location.href);
    if(window.location.hash.split('?')[0]==='#/activity'){
        next();
    }else{
        // 页面标题
        // document.title = getPageTitle(to.meta.title)
        if (loginStatus === 0) {
            // 微信未授权登录跳转到授权登录页面
            const url = window.location.href;
            // 解决重复登录url添加重复的code与state问题
            const parseUrl = qs.parse(url.split('?')[1]);
            let loginUrl;
            if (parseUrl.code && parseUrl.state) {
                delete parseUrl.code;
                delete parseUrl.state;
                loginUrl = `${url.split('?')[0]}?${qs.stringify(parseUrl)}`
            } else {
                loginUrl = url
            }
            // 设置微信授权回调地址
            wechatAuth.redirect_uri = loginUrl;
            // wechatAuth.redirect_uri = 'http://192.168.43.128:8080/article'
            // 无论拒绝还是授权都设置成1
            store.dispatch('setLoginStatus', 1);
            localStorage.setItem('loginStatus', 1);
            // 跳转到微信授权页面
            window.location.href = wechatAuth.authUrl
        }
        else if (loginStatus === 1) {
            // 用户已授权，获取code
            try {
                // 通过回调链接设置code status
                wechatAuth.returnFromWechat()
            } catch (err) {
                // 失败，设置状态未登录，刷新页面
                store.dispatch('setLoginStatus', 0);
                localStorage.setItem('loginStatus', 0);
                location.reload()
            }
            // 同意授权 to.fullPath 携带code参数，拒绝授权没有code参数
            const code = wechatAuth.code;
            if (code && code.code) {
                codes(code).then((res) => {
                    if (res.data.code === '0000') {
                        // this.$store.commit('setLoginStatus', 2);
                        // localStorage.setItem('login', '2');
                        localStorage.setItem('token', res.data.content.token);
                        localStorage.setItem('is_bind', res.data.content.is_bind);
                        localStorage.setItem('user_id', res.data.content.user_id);
                        localStorage.setItem('is_teacher', res.data.content.is_teacher);
                        localStorage.setItem('is_student', res.data.content.is_student);
                        // 拿到code 访问服务端的登录接口
                        store
                            .dispatch('loginWechatAuth', code)
                            .then(() => {
                                // 成功设置已登录状态
                                store.dispatch('setLoginStatus', 2);
                                localStorage.setItem('loginStatus', 2);
                                next()
                            })
                            .catch(() => {
                                // 失败，设置状态未登录，刷新页面
                                store.dispatch('setLoginStatus', 0);
                                localStorage.setItem('loginStatus', 0);
                                location.reload()
                            })

                    } else {
                        // this.$store.commit('setLoginStatus', 0);
                        // localStorage.setItem('login', '0');
                    }
                })

            } else {
                store.dispatch('setLoginStatus', 0);
                localStorage.setItem('loginStatus', 0);
                location.reload()
            }
        }
        else {
            // 已登录直接进入

            next()
        }
    }

});

function codes(codes) {
    let code = codes.code;
    return axios.post('wechatuser/getToken', {
        code,
        "is_integral": '1',
        // "wechat_id": "2",//测试服
        "wechat_id": "1"
    })
}

export default router
