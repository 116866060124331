import vuex from 'vuex'
import Vue from 'vue';
Vue.use(vuex)
export default new vuex.Store({
    state:{
        loginStatus:0,
        code:'',
        even:'',

        page:0,

        phone:''
    },
    mutations:{
        mSetLoginStatus(state,val){
            state.loginStatus=val
        },
        mLoginWechatAuth(state,val){
            state.code=val
        },
        setPage(state,val){
            state.page=val
        },
        setPhone(state,val){
            state.phone=val
        }
    },
    actions:{
        setLoginStatus(context,val){
            context.commit('mSetLoginStatus',val)
        },
        loginWechatAuth(context,val){
            context.commit('mLoginWechatAuth',val)
        },
        getPhone(context,val){
            context.commit('setPhone',val)
        }
    }
})
